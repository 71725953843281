









































import {Vue} from "vue-property-decorator";

export default class Start extends Vue {
}
